/* eslint-disable no-underscore-dangle */
import {ajax, getFromDataLayer, constructUrl} from '../lib/web'
import {zipMap, first} from '../lib/utils'
import * as Env from '../lib/Env'
import Screenshot from './Screenshot'

function FormUtils() {
  this.formData = {image: []}

  this.leadId = null
  this.sc = new Screenshot()

  const mapiUrl = window.mapiBaseUrl || Env.getMapiUrl()
  // eslint-disable-next-line no-underscore-dangle
  this._mapiBase = constructUrl(mapiUrl, 'leads')
  this.captureImage = true
  this.certField = 'xxTrustedFormCertUrl' // Name of the field TrustedForm inserts automatically

  // to prevent people from mashing the submit button over and over again
  window.formSubmitting = false
}

FormUtils.prototype = {
  constructor: FormUtils,

  // rather than capturing a screenshot, this uses a third-party that captures DOM / events
  // and stores it for us. this exists to support both submitByData and submitByForm without
  // changes from the FED (since submitByData forms may not be aware of the injected hidden input)
  captureActiveProspect(el) {
    if (this.formData[this.certField]) return

    const input = first(el.querySelectorAll(`input[name="${this.certField}"]`), i => i.value)
    if (!input || !input.value) return

    this.formData[this.certField] = input.value
  },

  capturePage(el) {
    this.captureActiveProspect(el)

    if (!this.captureImage || this.formData[this.certField]) {
      return Promise.resolve(this.formData)
    }

    return this.sc
      .capture(el)
      .then(enc => {
        this.formData.image.push(enc)
        return this.formData
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('Error generating image', e)
        delete this.formData.image
        return this.formData
      })
  },

  getFullMapiEndpoint(brand, source) {
    return constructUrl(this._mapiBase, [brand, source])
  },

  /**
   * getFromPage()
   *
   * @param key
   * @returns {*}
   */
  getFromPage(key) {
    const fromDl = getFromDataLayer(key)
    if (fromDl !== undefined) return fromDl
    return window.cl_gtm ? window.cl_gtm[key] : false
  },

  appendObjectToFormData(object) {
    this.FormData = Object.assign(this.formData, object)
    return this.formData
  },

  disableImageCapture() {
    this.captureImage = false
  },

  /**
   * SubmitByForm()
   * automagically submit form, and does redirect if passed in
   * @param {string} brand
   * @param {string} source
   * @param form
   * @param redirect
   */
  submitByForm(brand, source, form, redirect = false) {
    return this._getFormData(form).then(data => this.submitByData(brand, source, data, redirect))
  },

  /**
   * _getFormData()
   * retrieve data object from form
   * @param form
   * @returns {*}
   */
  _getFormData(form) {
    const querystring = 'input:not([type="submit"]):not([type="radio"]),input:checked,select,textarea'

    const elements = form.querySelectorAll(querystring)
    const formData = zipMap(elements, i => {
      if (!i.value) return null
      return [i.name, i.value]
    })

    return Promise.resolve(formData)
  },

  /**
   * submitByData()
   * method if submitting previously assembled object, redirects to redirect if previously defined
   * @param {string} brand
   * @param {string} source
   * @param data
   * @param redirect
   * @param target optional, the target capture element
   */
  // eslint-disable-next-line no-undef
  submitByData(brand, source, data, redirect, target = document.body) {
    const additional = this._getAdditional()
    this.appendObjectToFormData(additional)
    this.appendObjectToFormData(data)
    const endpoint = this.getFullMapiEndpoint(brand, source)

    if (window.formSubmitting) {
      return 'already there'
    }
    window.formSubmitting = true

    return this.capturePage(target).then(fd =>
      ajax(endpoint, 'POST', {data: fd})
        .then(response => {
          // Capture lead id
          if ('data' in response) {
            this.leadId = response.data.uuid
          }

          if (redirect) {
            window.location.href = redirect
          }

          this.formData.image = []

          window.formSubmitting = false
          return response
        })
        .catch(xhr => {
          // Remove the last image. Since multi-part forms might have multiple images,
          // we don't necessary want to remove them all
          this.formData.image.splice(-1, 1)
          window.formSubmitting = false
          throw window.JSON.parse(xhr.responseText)
        }),
    )
  },
  /**
   * * updateByData()
   * method if updating previously submitted lead object, redirects to redirect if previously defined
   * @param data
   */
  updateByData(data) {
    if (!this.leadId) {
      return {error: 'Lead ID does not exist'}
    }

    this.appendObjectToFormData(data)
    const endpoint = `${this._mapiBase}/update/${this.leadId}`

    // eslint-disable-next-line no-undef
    return this.capturePage(document.body).then(fd =>
      ajax(endpoint, 'PUT', {data: fd}).then(() => {
        this.formData.image = []
      }),
    )
  },

  /**
   * getAdditional()
   * Sets promo code, request id and domain from data layer
   */
  _getAdditional() {
    return {
      promo_code:
        this.getFromPage('promo_code') || this.getFromPage('promoCode') || window.mapiDefaultPromo || window.PROMO,
      request_id: this.getFromPage('request_id') || this.getFromPage('requestID') || '',
      domain: window.location.href,
    }
  },

  // this is needed to deal with the screenshot issue
  // where checkbox and select elements only display their html value
  // hopefully can be removed when the underlying library bug is fixed
}

export default FormUtils
